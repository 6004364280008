import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"formOrderPreview",staticStyle:{"width":"100%"}},[(_vm.formOrder.show)?_c(VForm,{staticClass:"hotel-feedback__form"},[_vm._l((_vm.formOrder.pro),function(form,indexForm){return _c('div',{key:indexForm},[_c('div',{style:(("color:" + (_vm.landing.order_form.styles.title_color) + ";font-weight:bold;margin-bottom:15px;"))},[_vm._v(" "+_vm._s(form.title)+" ")]),_vm._l((form.content),function(subForm,indexSubForm){return _c('div',{key:indexSubForm,staticStyle:{"margin-left":"15px"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","margin-bottom":"15px"}},[_c('div',{staticStyle:{"padding":"0","display":"flex","align-items":"center","justify-content":"space-between","text-align":"left"},style:(("color:" + (_vm.landing.order_form.styles.label_color)))},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(subForm.radio === 'checkbox')?_c(VCheckbox,{attrs:{"on-icon":"mdi-checkbox-outline","off-icon":"mdi-checkbox-blank-outline","value":true,"type":"checkbox","hide-details":""}}):_vm._e(),_c(VRadioGroup,{attrs:{"row":"","hide-details":""},model:{value:(form.groupCheck),callback:function ($$v) {_vm.$set(form, "groupCheck", $$v)},expression:"form.groupCheck"}},[(subForm.radio === 'radio')?_c(VRadio,{key:subForm.label,attrs:{"value":subForm.label}}):_vm._e()],1),_c('div',[_vm._v(" "+_vm._s(subForm.label)+" ")])],1),(
              subForm.price && subForm.radio === 'checkbox' || subForm.price && subForm.radio === 'text'|| subForm.price && subForm.radio === 'radio'
            )?_c('div',{style:(("color:" + (_vm.landing.order_form.styles.price_color) + ";"))},[_vm._v(" +"+_vm._s(subForm.price)+" "+_vm._s(_vm.landing.advanced.global.currency)+" ")]):_vm._e()]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center"}},[(subForm.radio === 'number')?_c('vue-numeric-input',{staticStyle:{"margin-right":"15px","width":"100px"},attrs:{"width":"100px","inline":"","controls":""},model:{value:(subForm.count),callback:function ($$v) {_vm.$set(subForm, "count", $$v)},expression:"subForm.count"}}):_vm._e(),(
              subForm.price && subForm.radio === 'number'
            )?_c('div',{style:(("color:" + (_vm.landing.order_form.styles.price_color) + ";"))},[_vm._v(" +"+_vm._s(subForm.price)+" "+_vm._s(_vm.landing.advanced.global.currency)+" ")]):_vm._e()],1),(subForm.radio === 'time')?_c('div',[(subForm.radio === 'text')?_c(VTextField,{staticStyle:{"max-width":"60%"},attrs:{"outlined":"","dense":"","background-color":"rgba(255,255,255,0.5)","hide-details":""},model:{value:(subForm.text),callback:function ($$v) {_vm.$set(subForm, "text", $$v)},expression:"subForm.text"}}):_vm._e()],1):_vm._e(),(subForm.radio === 'date')?_c('div',{staticStyle:{"display":"flex","padding":"0","align-items":"center"},style:(("color:" + (_vm.landing.order_form.styles.label_color)))},[_c(VMenu,{attrs:{"close-on-content-click":"","transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c(VTextField,_vm._g({staticStyle:{"max-width":"60%"},attrs:{"append-icon":"mdi-calendar","readonly":"","hide-details":"","outlined":"","dense":"","value":_vm.formatDate(subForm.text)}},on))]}}],null,true)},[_c(VDatePicker,{attrs:{"locale":"en-in","no-title":""},model:{value:(subForm.text),callback:function ($$v) {_vm.$set(subForm, "text", $$v)},expression:"subForm.text"}})],1)],1):_vm._e(),(subForm.radio === 'time')?_c('div',{staticStyle:{"padding":"0","display":"flex","align-items":"center"},style:(("color:" + (_vm.landing.order_form.styles.label_color)))},[_c(VMenu,{attrs:{"close-on-content-click":"","transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c(VTextField,_vm._g({staticStyle:{"max-width":"60%"},attrs:{"append-icon":"mdi-clock","readonly":"","hide-details":"","outlined":"","dense":"","value":subForm.text}},on))]}}],null,true)},[_c(VTimePicker,{attrs:{"locale":"en-in","no-title":""},model:{value:(subForm.text),callback:function ($$v) {_vm.$set(subForm, "text", $$v)},expression:"subForm.text"}})],1)],1):_vm._e()]),_c('div',[(subForm.radio === 'notice')?_c(VTextarea,{attrs:{"outlined":"","solo":"","dense":""}}):_vm._e()],1)])})],2)}),_c(VBtn,{staticClass:"hotel-reviews__submit",staticStyle:{"margin":"15px 0"},style:(("color:" + (_vm.formOrder.btn_color) + ";background-color:" + (_vm.formOrder.btn_bg) + ";border-color:" + (_vm.formOrder.btn_border) + ";")),attrs:{"type":"button","depressed":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.formOrder.btn_name)+" ")])],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }