<template>
  <div class="formOrderPreview" style="width:100%;">
    <v-form class="hotel-feedback__form" v-if="formOrder.show">
      <div v-for="(form, indexForm) in formOrder.pro" :key="indexForm">
        <div
          :style="`color:${landing.order_form.styles.title_color};font-weight:bold;margin-bottom:15px;`"
        >
          {{ form.title }}
        </div>
        <div
        style="margin-left:15px;"
          v-for="(subForm, indexSubForm) in form.content"
          :key="indexSubForm"
        >

          <div style="display: flex; flex-direction:column;margin-bottom:15px;">
            <div
              :style="`color:${landing.order_form.styles.label_color}`"
              style=" padding:0;display:flex;align-items:center;justify-content:space-between;text-align:left;"
            >
            <div style="display:flex;align-items:center;">
            <v-checkbox
              on-icon="mdi-checkbox-outline"
              off-icon="mdi-checkbox-blank-outline"
              v-if="subForm.radio === 'checkbox'"
              :value="true"
              type="checkbox"
              hide-details
              ></v-checkbox>
                <v-radio-group row hide-details v-model="form.groupCheck">
                  <v-radio
                    v-if="subForm.radio === 'radio'"
                    :value="subForm.label"
                    :key="subForm.label"
                  >
                  </v-radio>
                </v-radio-group>
            <div>
              {{ subForm.label }}
              </div>
              </div>
            <div
              v-if="
                subForm.price && subForm.radio === 'checkbox' || subForm.price && subForm.radio === 'text'|| subForm.price && subForm.radio === 'radio'
              "
              :style="`color:${landing.order_form.styles.price_color};`"
            >
               +{{ subForm.price }} {{landing.advanced.global.currency}}
            </div>
            </div>
            <div style="display:flex;justify-content:space-between;align-items:center;">
            <vue-numeric-input style="margin-right:15px;width:100px;" width="100px" 
              v-if="subForm.radio === 'number'"
              v-model="subForm.count" inline controls />
            <div
              v-if="
                subForm.price && subForm.radio === 'number'
              "
              :style="`color:${landing.order_form.styles.price_color};`"
            >
               +{{ subForm.price }} {{landing.advanced.global.currency}}
            </div>
            </div>
            <div v-if="subForm.radio === 'time'">
              <v-text-field
                outlined
                dense
                background-color="rgba(255,255,255,0.5)"
                v-model="subForm.text"
                v-if="subForm.radio === 'text'"
                style="max-width: 60%"
                hide-details
              >
              </v-text-field>
            </div>
            <div
              v-if="subForm.radio === 'date'"
              :style="`color:${landing.order_form.styles.label_color}`"
              style="display: flex;padding: 0;align-items: center;">
              <v-menu
                close-on-content-click
                
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    append-icon="mdi-calendar"
                    readonly
                    hide-details
                    outlined
                    dense
                    :value="formatDate(subForm.text)"
                    style="max-width: 60%"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="en-in"
                  v-model="subForm.text"
                  no-title
                ></v-date-picker>
              </v-menu>
            </div>
            <div
              v-if="subForm.radio === 'time'"
              :style="`color:${landing.order_form.styles.label_color}`"
              style="padding: 0;display: flex;align-items: center;"
            >
              <v-menu
                close-on-content-click
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    append-icon="mdi-clock"
                    readonly
                    hide-details
                    outlined
                    style="max-width: 60%"
                    dense
                    :value="subForm.text"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  locale="en-in"
                  v-model="subForm.text"
                  no-title
                ></v-time-picker>
              </v-menu>
            </div>
          </div>
        <div>
          <v-textarea outlined solo dense v-if="subForm.radio === 'notice'">

          </v-textarea>
        </div>
        </div>
      </div>
      <v-btn
        class="hotel-reviews__submit"
        type="button"
        depressed
        outlined
        small
        style="margin: 15px 0"
        :style="
          `color:${formOrder.btn_color};background-color:${formOrder.btn_bg};border-color:${formOrder.btn_border};`
        "
      >
        {{ formOrder.btn_name }}
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "FormOrderPreview",
  computed: {
    ...mapState(["landing"]),
  },
  props: ["formOrder"],
  methods: {
    formatDate(date) {
      return moment(date).format("DD.MM.yyyy");
    },
  },
  components:{
  }
};
</script>

<style lang="scss">
.vue-numeric-input .btn-increment .btn-icon{
  background:#fff;
}
.vue-numeric-input .btn-decrement .btn-icon{
  background:#fff;
}
.vue-numeric-input .btn-decrement .btn-icon:before{
  background-size:50% 50%;
}
.vue-numeric-input .btn-increment .btn-icon:before{
  background-size:50% 50%;
}
</style>
